<template>
  <div class="d-flex flex-column main-body">
    <div class="section section1">
      <v-img
        src="@/assets/images/header2.jpg"
        lazy-src="https://via.placeholder.com/300.webp/?text=loading"
        max-height="100vh"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="article section2">
      <div class="d-flex flex-column">
        <div class="pa-2 text-center text-h4">Knowledge and Understanding</div>
        <div class="pa-2 text-center" style="font-size: 16pt;">Positioned in one of the residential district in BSD City, we provide education for children starting their first school as a Kindergarten, to Primary, Secondary, and High School. Our school's main purpose is to instill knowledge and understanding to the young minds as well as disciplining them with the correct attitude and characters needed to survive and thrive in this modern society.</div>
        <!-- <div class="pa-2 text-center">Register Your Interest</div> -->
      </div>
    </div>
    <div class="article section3">
      <div class="pa-2 text-center text-h4">Anderson School Indonesia</div>
      <div class="d-flex flex-row justify-center">
        <div class="pa-2 d-flex flex-column myarticle">
          <div class="pa-2 text-justify">For more than a decade, Anderson School have been nurturing young minds, emphasizing on their character as well as their knowledge. We provide education for children starting their first step of their academic journey in Kindergarten all the way to High School programmes. Our school welcomes students of ages 4 and above and prepare them for their next step in their journey.</div>
          <div class="pa-2 text-justify">As an experienced academic institution, we know how important knowledge is for children. But we also know that the correct character also define their success. Not only do we try to feed the mind, we also build the heart and the soul, the emotion that drives a student, teaching them from right to wrong.</div>
          <div class="pa-2 text-justify">Our academic curriculum adopts a National-Plus syllabus. Not only do our curriculum adheres to the national standards given by the Board of Education, we also supplement our students with international curriculums to prepare them should they choose to further their education abroad.</div>
        </div>
        <div class="pa-2">
          <v-card>
            <v-img src="@/assets/images/img10.jpg" width=500 height=500>
            </v-img>
          </v-card>
        </div>
      </div>
    </div>
    <div class="article section4">
      <div class="d-flex flex-row justify-center">
        <v-col>
          <v-row class="md6">
            <v-col cols="6">
              <v-card flat>
                <v-img src="@/assets/images/img7.jpg"></v-img>
                <v-card-text>
                  <v-col class="text-h5">
                    Kindergarten
                  </v-col>
                  <v-col>
                    The very first step of your child's academic journey
                  </v-col>
                  <v-col>
                    Our kindergarten welcomes children age 3 to 5 as a stepping stone for their primary education. Students will be prepared for primary education, learning the basics necessities that they will need to further their education.
                  </v-col>
                  <v-col>
                    <!-- <v-btn dark color="yellow darken-2">Learn More</v-btn> -->
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat>
                <v-img src="@/assets/images/img9.jpg"></v-img>
                <v-card-text>
                  <v-col class="text-h5">
                    Primary Programme
                  </v-col>
                  <v-col>
                    The next big step of your child's life
                  </v-col>
                  <v-col>
                    Designated for children age 6 to 12, our six year primary school programme aims at building both our student's academic skills as well as their character.
                  </v-col>
                  <v-col>
                    <!-- <v-btn dark color="yellow darken-2">Learn More</v-btn> -->
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-img src="@/assets/images/img15.jpg"></v-img>
                <v-card-text>
                  <v-col class="text-h5">
                    Secondary Programme
                  </v-col>
                  <v-col>
                    Preparing student for their further education
                  </v-col>
                  <v-col>
                    Our secondary or middle-school programme aims to further deepen our student's knowledge and improve their already established character. Designated for children aged 12 to 15.
                  </v-col>
                  <v-col>
                    <!-- <v-btn dark color="yellow darken-2">Learn More</v-btn> -->
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat>
                <v-img src="@/assets/images/img14.jpg"></v-img>
                <v-card-text>
                  <v-col class="text-h5">
                    High-School Programme
                  </v-col>
                  <v-col>
                    The end of the beginning of our student's academic journey
                  </v-col>
                  <v-col>
                    The final years of general education, but not the end of our student's academic journey. The programme is meant to improve student's knowledge, preparing them for higher education, and giving them the skills and characters essential for today's standard.
                  </v-col>
                  <v-col>
                    <!-- <v-btn dark color="yellow darken-2">Learn More</v-btn> -->
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col></v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>
.section1 {
  height: 100vh;
}
.myarticle {
  letter-spacing: 0.1em;
}
.article {
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 300px;
  padding-right: 300px;
}
.main-body {
  margin-top: -64px;
}
</style>
